<template>
  <main class="career-wrap">
    <section class="career-top intro">
      <div class="bg-overlay">
        <div class="intro-zh-bg"></div>
      </div>
      <div class="content">
        <div class="left-con">
          <h1 class="headline-semi-l">{{ career.position }}</h1>
        </div>
        <div class="right-con">
          <p @click="scrollToApply" class="career-btn-wrapper">
            <router-link class="btn-black" to="#apply"
              ><span class="btn-txt">Apply Now</span><i class="arrow-right"></i
            ></router-link>
          </p>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="career-detail ">
        <aside class="career-left-con">
          <h2 class="career-title">{{ career.place }}</h2>
          <p class="career-title career-time">{{ career.time }}</p>
        </aside>
        <section class="career-right-con">
          <dl class="career-list" v-if="career.descri">
            <dt class="career-title">DESCRIPTION:</dt>
            <dd
              v-for="d in career.descri.split('\n')"
              :key="`decri-${d.index}`"
            >
              {{ d }}
            </dd>
          </dl>
          <dl class="career-list" v-if="career.responsibility">
            <dt class="career-title">RESPONSIBILITIES:</dt>
            <dd
              class="career-item"
              v-for="(r, i) in career.responsibility.split('\n')"
              :key="`re-${i}`"
            >
              {{ r }}
            </dd>
          </dl>
          <dl class="career-list" v-if="career.required">
            <dt class="career-title">REQUIRED:</dt>
            <dd
              class="career-item"
              v-for="(rd, i) in career.required.split('\n')"
              :key="`required-${i}`"
            >
              {{ rd }}
            </dd>
          </dl>
          <dl class="career-list" v-if="career.plus">
            <dt class="career-title">PLUS:</dt>
            <dd
              class="career-item"
              v-for="(p, i) in career.plus.split('\n')"
              :key="`plus-${i}`"
            >
              {{ p }}
            </dd>
          </dl>
          <dl class="career-list" v-if="career.materials">
            <dt class="career-title">MATERIALS:</dt>
            <dd
              class="career-item"
              v-for="(m, i) in career.materials.split('\n')"
              :key="`m-${i}`"
            >
              {{ m }}
            </dd>
          </dl>
        </section>
      </div>

      <section class="career-apply" id="apply">
        <aside class="career-left-con">
          <h2 class="career-title">APPLICATION</h2>
          <p class="career-title career-time">
            YOU CAN ALSO SEND A RESUME EMAIL DIRECTLY IF YOU HAVE PROBLEMS USING
            THE APPLICATION FORM
          </p>
          <p class="career-email"><a>Careers@recreate.games</a></p>
        </aside>
        <section class="career-right-con">
          <form>
            <p class="input-wrap">
              <label class="label" for="name">YOUR NAME *</label>
              <input
                v-model.trim="userName"
                @blur="invalidationNameCheck()"
                autocomplete="off"
                :class="{ warn: invalidation.name }"
                class="input"
                id="name"
                type="text"
                required
              />
            </p>
            <p class="input-wrap">
              <label class="label" for="email">EMAIL ADDRESS *</label>
              <input
                v-model.trim="emailAddr"
                @blur="invalidationEmailCheck()"
                autocomplete="off"
                :class="{ warn: invalidation.email }"
                class="input"
                id="email"
                type="email"
                required
              />
            </p>
            <p class="input-wrap">
              <label class="label" for="mobile">MOBILE PHONE</label>
              <input
                v-model.trim.number="mobile"
                autocomplete="off"
                id="mobile"
                class="input"
                type="number"
              />
            </p>
            <p class="input-wrap selector">
              <label>
                <select v-model="hearFrom" class="select">
                  <option
                    :value="source"
                    v-for="(source, index) in positionOrigin"
                    :key="index"
                    >{{ source }}</option
                  >
                </select>
              </label>
            </p>
            <div class="file-size-tips">
              <p class="file-size-tips-icon">
                *
              </p>
              <p>
                Please ensure that the total file size is less than 10MB. If the
                total exceeds 10MB, please Email
                <a href="mailto:Careers@recreate.games"
                  >Careers@recreate.games</a
                >
              </p>
            </div>
            <p class="input-wrap">
              <label class="label" for="resume">CURRICULUM VITAE *</label>
              <span class="file" :class="{ warn: invalidation.resume }">
                <span class="btn-upload"
                  >CHOOSE FILE
                  <input
                    id="resume"
                    ref="resumeInput"
                    class="file-input"
                    type="file"
                    required
                    @change="getResume($event)"
                  />
                </span>
                <span v-if="resume.length">{{ resume[0].name }}</span>
                <span class="file-size" v-if="resume.length"
                  >, {{ resumeSize }}</span
                >
                <span
                  class="btn-delete"
                  v-if="resume.length"
                  @click="delResume()"
                  >X</span
                >
              </span>
            </p>
            <p class="input-wrap">
              <label class="label" for="letter">COVER LETTER</label>
              <span class="file">
                <span class="btn-upload"
                  >CHOOSE FILE
                  <input
                    id="letter"
                    ref="letterInput"
                    class="file-input"
                    type="file"
                    @change="getLetter($event)"
                  />
                </span>
                <span v-if="letter.length">{{ letter[0].name }}</span>
                <span class="file-size" v-if="letter.length"
                  >, {{ letterSize }}</span
                >
                <span
                  class="btn-delete"
                  v-if="letter.length"
                  @click="delLetter()"
                  >X</span
                >
              </span>
            </p>
            <p class="input-wrap">
              <label class="label" for="attachment">EXTRA ATTACHMENTS</label>
              <span class="file">
                <span class="btn-upload"
                  >CHOOSE FILE
                  <input
                    id="attachment"
                    ref="attachmentInput"
                    class="file-input"
                    type="file"
                    @change="getAttachment($event)"
                  />
                </span>
                <span v-if="attachment.length">{{ attachment[0].name }}</span>
                <span class="file-size" v-if="attachment.length"
                  >, {{ attachmentSize }}</span
                >
                <span
                  class="btn-delete"
                  v-if="attachment.length"
                  @click="delAttachment()"
                  >X</span
                >
              </span>
            </p>
            <div class="input-wrap">
              <p :class="stateOfRequest" class="btn-submit" @click="send()">
                <!--@click="send() testSend()"-->
                <span class="txt">Send Application</span>
                <span class="loading-icon"
                  >Sending<i class="loading-ico"></i
                ></span>
                <span class="done-icon">Done<i class="done-ico"></i></span>
                <span class="error-icon">Error<i class="error-ico"></i></span>
              </p>
              <p class="form-notice">
                <span
                  :class="{ show: isShowNotice && stateOfRequest === 'error' }"
                  class="notice notice-error"
                  >Something went wrong. Please try again later.</span
                >
                <span
                  :class="{ show: isShowNotice && stateOfRequest === 'done' }"
                  class="notice notice-success"
                  >Thank you for your Application.</span
                >
                <span
                  :class="{
                    show: isShowNotice && stateOfRequest === 'oversize',
                  }"
                  class="notice notice-error"
                >
                  {{
                    "The total file size exceeds 10MB. Please select new files and resubmit. Thank you :)"
                  }}
                </span>
                <!--                <span class="notice-warn">One or more fields have an error. Please check and try again.</span>-->
                <!--                <span class="notice-warn">The file is too big.</span>-->
              </p>
            </div>
          </form>
        </section>
      </section>
    </section>

    <section class="career-bottom">
      <div class="content">
        <p class="career-title">JOIN RECREATE</p>
        <router-link class="btn-black" to="/en/#careers"
          ><span class="btn-txt">MORE POSITIONS</span><i class="arrow-right"></i
        ></router-link>
      </div>
    </section>
  </main>
</template>

<script>
import { sendEmail } from "@/api";
import { emailRegExp } from "@/utils/utils";

export default {
  name: "CareerEn",
  data() {
    return {
      careerId: this.$route.params.career,
      career: {
        id: null,
        position: "",
        place: "",
        time: "",
        descri: "",
        responsibility: "",
        required: "",
        plus: "",
        materials: "",
      },
      userName: "",
      emailAddr: "",
      mobile: "",
      hearFrom: "",
      resume: [],
      letter: [],
      attachment: [],
      resumeSize: "",
      letterSize: "",
      attachmentSize: "",
      positionOrigin: [
        "Where did you hear about this position",
        "News",
        "Recruiting Website",
        "Search Engine",
        "ArtStation",
        "Wechat",
        "Friend",
        "Someone At Recreate",
        "Other",
      ],
      isShowNotice: false,
      pending: false, // pending request of sending email
      invalidation: {
        name: false,
        email: false,
        resume: false,
      },
      stateOfRequest: "", // '', 'loading', 'error', 'done', 'oversize'
    };
  },
  props: {
    careers: Array,
  },
  beforeMount() {
    document.documentElement.style.scrollBehavior = "auto";
  },
  mounted() {
    this.$emit("update-nav", null);

    this.career = this.careers.find(
      (item) => item.id === Number(this.careerId)
    );
    this.hearFrom = this.positionOrigin[0];
    if (!this.career) {
      this.$router.push("/en/error");
    }
    this.resume = this.$refs.attachmentInput.files;
    this.letter = this.$refs.attachmentInput.files;
    this.attachment = this.$refs.attachmentInput.files;
  },
  methods: {
    scrollToApply() {
      document.querySelector("#apply").scrollIntoView();
    },
    backToCareers() {
      this.$emit("update-nav", 2);
    },
    invalidationNameCheck() {
      this.invalidation.name = !this.userName;
    },
    invalidationEmailCheck() {
      this.invalidation.email = !emailRegExp.test(this.emailAddr);
    },
    invalidationResumeCheck() {
      this.invalidation.resume = !this.resume.length;
    },
    formatFileSize(size) {
      const sizeMB = (size / (1024 * 1024)).toFixed(2);
      return `${sizeMB} MB`;
    },
    getResume(e) {
      this.resume = e.target.files;
      if (this.resume.length) {
        this.resumeSize = this.formatFileSize(this.resume[0].size);
      }
      this.invalidationResumeCheck();
    },
    delResume() {
      this.$refs.resumeInput.value = "";
      this.resumeSize = "";
      this.resetErrorTips();
      this.$forceUpdate();
    },
    getLetter(e) {
      this.letter = e.target.files;
      if (this.letter.length) {
        this.letterSize = this.formatFileSize(this.letter[0].size);
      }
    },
    delLetter() {
      this.$refs.letterInput.value = "";
      this.letterSize = "";
      this.resetErrorTips();
      this.$forceUpdate();
    },
    getAttachment(e) {
      this.attachment = e.target.files;
      if (this.attachment.length) {
        this.attachmentSize = this.formatFileSize(this.attachment[0].size);
      }
    },
    delAttachment() {
      this.$refs.attachmentInput.value = "";
      this.attachmentSize = "";
      this.resetErrorTips();
      this.$forceUpdate();
    },
    send() {
      let formData = new FormData();
      let subject = "",
        message = "";

      if (this.pending) return;

      this.invalidationNameCheck();
      this.invalidationEmailCheck();
      this.invalidationResumeCheck();

      if (
        this.invalidation.name ||
        this.invalidation.email ||
        this.invalidation.resume
      )
        return;

      subject = `Application from ${this.userName} – ${this.career.position} (${this.career.time})`;

      message = `
          ${this.userName} 通过 Recreate.games 投递简历

          Email:
          ${this.emailAddr}

          Mobile Phone:
          ${this.mobile}

          Apply For The Position:
          ${this.career.position}

          Employment Type:
          ${this.career.time}

          Hear About This position From:
          ${this.hearFrom === this.positionOrigin[0] ? "" : this.hearFrom}

          ----
          This email was sent from recreate.games.`;

      // Calculate total attachments size
      let totalSize = 0;
      if (this.resume.length) totalSize += this.resume[0].size;
      if (this.letter.length) totalSize += this.letter[0].size;
      if (this.attachment.length) totalSize += this.attachment[0].size;

      // Convert to MB with 2 decimal places
      const totalSizeMB = (totalSize / (1024 * 1024)).toFixed(2);
      console.log(`Total attachments size: ${totalSizeMB} MB`);

      // Limit attachment size to 10MB
      if (totalSizeMB > 10) {
        this.oversize();
        return;
      }

      formData.append("from", "hello@recreate.games");
      formData.append("to", "careers@recreate.games"); // 444092771@qq.com
      // formData.append("to", "1016837641@qq.com"); // 444092771@qq.com
      formData.append("replyTo", "hr@recreategame.com");
      // formData.append("replyTo", "1016837641@qq.com"); // 444092771@qq.com
      formData.append("subject", subject);
      formData.append("body", message);
      if (this.resume.length) formData.append("attachment", this.resume[0]);
      if (this.letter.length) formData.append("attachment", this.letter[0]);
      if (this.attachment.length)
        formData.append("attachment", this.attachment[0]);

      this.stateOfRequest = "loading";
      this.pending = true;

      sendEmail(formData)
        .then((res) => {
          if (res.data.code === 0) {
            this.success();
          } else {
            this.error();
          }
        })
        .catch(() => {
          this.error();
        });
    },
    showNotice(persist) {
      this.isShowNotice = true;
      if (persist) {
        return;
      }
      // setTimeout(() => {
      //   this.isShowNotice = false;
      //   this.resetBtn();
      // }, 5000);
    },
    success() {
      this.stateOfRequest = "done";
      this.showNotice();
      // setTimeout(() => {
      //   this.resetForm();
      // }, 5000);
    },
    error() {
      this.stateOfRequest = "error";
      this.showNotice(true);
    },
    resetBtn() {
      this.pending = false;
      this.stateOfRequest = "";
    },
    oversize() {
      this.stateOfRequest = "oversize";
      this.showNotice(true);
    },
    resetForm() {
      this.resetBtn();
      this.hearFrom = this.positionOrigin[0];
      this.userName = "";
      this.emailAddr = "";
      this.mobile = "";
      this.resumeSize = "";
      this.letterSize = "";
      this.attachmentSize = "";
      this.delResume();
      this.delLetter();
      this.delAttachment();
    },
    resetErrorTips() {
      this.isShowNotice = false;
      this.resetBtn();
    },
  },
};
</script>
